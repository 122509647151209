<template>
  <div class="Login">
    <div class="Login-LeftSide p-4">
      <div class="LoginForm">
        <div class="LoginForm-Logo mb-4">
          <div class="Title mb-1">Welcome to</div>
          <AppLogo />
        </div>
        <b-form class="LoginForm-Form" novalidate @submit.stop.prevent="onSubmit">
          <LoginBrands v-if="!isHoldingDomain" class="mb-4" @select-brand="onSelectBrand" />
          <b-form-group
            id="form-login-group"
            label-class="Label mb-1"
            :label="`${$t('labels.login')}:`"
            label-for="form-login"
            class="mb-3"
          >
            <b-form-input
              id="form-login"
              v-model.trim.lazy="$v.form.email.$model"
              class="Input Medium"
              :class="{ 'Input--error': !validateState('email') && $v.form.email.$model }"
              type="email"
              autocomplete="on"
              :placeholder="`${$t('placeholders.enter-login')}`"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="form-password-group"
            label-class="Label mb-1"
            :label="`${$t('labels.password')}:`"
            label-for="form-password"
            class="mb-4"
          >
            <div class="LoginForm-InputWithIcon">
              <b-form-input
                id="form-password"
                v-model.trim.lazy="$v.form.password.$model"
                class="Input Medium"
                :class="{ 'Input--error': !validateState('password') && $v.form.password.$model }"
                :type="isShowPassword ? 'text' : 'password'"
                autocomplete="on"
                :placeholder="`${$t('placeholders.enter-password')}`"
              ></b-form-input>
              <span class="LoginForm-PasswordIcon" @click="isShowPassword = !isShowPassword">
                <BaseIcon :name="eyeIcon" fill stroke />
              </span>
            </div>
            <router-link :to="{ name: 'restore-password:email' }" class="LoginForm-RestorePassword">
              {{ $t('base-actions.forgotPassword') }}
            </router-link>
          </b-form-group>
          <b-button
            :disabled="$v.form.$anyError"
            type="submit"
            class="Button Button-Main Medium Full"
          >
            {{ $t('base-actions.enter') }}
          </b-button>
        </b-form>
      </div>
      <div class="Copyrights mt-5 mt-md-0">
        &copy; {{ $t('copyright') }}, {{ `2021—${nowYear}` }}
      </div>
    </div>
    <div class="Login-RightSide">
      <video-background
        v-if="!isMobile"
        class="Login-Video"
        :src="require(`@/assets/Video/galaxy.mp4`)"
        :poster="require(`@/assets/Images/galaxy.jpg`)"
        style="height: 100vh"
      />
      <AppLegalNavigation class="p-4" />
    </div>
  </div>
</template>

<script>
import BaseIcon from '@/components/Base/BaseIcon';

import AppLogo from '@/components/App/AppLogo';
import AppLegalNavigation from '@/components/App/AppLegalNavigation';
import LoginBrands from '@/views/Auth/_components/LoginBrands';

import VideoBackground from 'vue-responsive-video-background-player';
import { required, email, minLength } from 'vuelidate/lib/validators';
import { BRAND_SLUG } from '@/config';
import { HelperRegEx } from '@/services/Helpers';

import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'Login',
  components: { AppLegalNavigation, BaseIcon, AppLogo, LoginBrands, VideoBackground },
  data: () => ({
    form: {
      email: '',
      password: '',
      holdingSlug: BRAND_SLUG,
    },
    isShowPassword: false,
    date: new Date(),
  }),
  computed: {
    ...mapGetters('Settings', ['isMobile']),
    eyeIcon() {
      return this.isShowPassword ? 'eye' : 'eye-close';
    },
    nowYear() {
      return this.date.getFullYear();
    },
    isHoldingDomain() {
      return HelperRegEx.checkHoldingDomain(BRAND_SLUG) !== -1;
    },
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(3),
      },
      holdingSlug: {
        required,
      },
    },
  },
  methods: {
    ...mapActions('Admin', ['loginUser']),
    onSelectBrand(name) {
      this.form.holdingSlug = name;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form?.[name] || {};
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.$v.$touch();
      if (!this.$v.form.$anyError) {
        this.loginUser({ ...this.form, locale: this.$i18n.locale });
      } else return false;
    },
  },
};
</script>
