export const HelperURLSearchParams = {
  createRequestParams: (params) =>
    Object.entries(params)
      .reduce((usp, [k, v]) => {
        if (v !== '') usp.append(k, v);
        return usp;
      }, new URLSearchParams())
      .toString(),
  getRequestParams: (param) => new URLSearchParams(window.location.search).get(param),
};
