import { AuthGuard } from '@/router/Auth-guard';

import UiKit from '@/views/UiKit/UiKit';

export default [
  {
    path: '/questionary/start',
    name: 'api:questionary:start',
    props: true,
    beforeEnter: AuthGuard.redirectToTestingPage,
  },
  {
    path: '/questionary/testing',
    redirect: { name: 'questionary:testing' },
  },
  {
    path: '/ui-kit',
    name: 'ui-kit',
    component: UiKit,
    beforeEnter: AuthGuard.isAdmin,
  },
  {
    path: '*',
    props: true,
    beforeEnter: AuthGuard.redirectToHome,
  },
];
