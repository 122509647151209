import { Auth } from '@/services/General/Auth';

import store from '@/store';

export const AuthGuard = {
  isAdmin: (to, from, next) => {
    const token = Auth.getAuthToken();
    if (token && token.length > 0) {
      if (
        to.name === 'home' &&
        store.getters['Users/userAvailableRoutes'].length &&
        !store.getters['Users/userAvailableRoutes'].includes('questionary_questionary_list')
      ) {
        next({ name: 'reports' });
      }
      next();
    } else {
      store.dispatch('setRouteUserFrom', to.name);
      next({ name: 'login' });
    }
  },
  isAuth: (to, from, next) => {
    const token = Auth.getAuthToken();
    if (token && token.length > 0) {
      next({ name: 'home' });
    } else {
      next();
    }
  },

  // При переходе по ссылке из рассылки делаем редирект на страницу начала тестирования
  redirectToTestingPage: (to, from, next) => {
    const { token } = to.query;
    if (token) {
      store
        .dispatch('Testing/testingStart', { token })
        .then(() => {
          next({ name: 'testing:information', params: { token } });
        })
        .catch(() => {
          next({ name: 'home' });
        });
    } else next({ name: 'home' });
  },

  redirectToHome: (to, from, next) => {
    next({ name: 'home' });
  },

  // При переходе по ссылке из рассылки делаем редирект на страницу смены пароля
  redirectToSetNewPassword: (to, from, next) => {
    const { token } = to.query;
    if (token) {
      next({ name: 'restore-password:token', params: { token } });
    } else next({ path: 'restore-password' });
  },
};
