<template>
  <div v-if="filteredBrands" class="LoginBrands">
    <div
      v-for="({ name, slug, frontendHost, isActive }, index) in filteredBrands"
      :key="`brand-${index}`"
      :class="['LoginBrand', { 'LoginBrand--active': isActive }]"
      @click="onSelectBrand(slug)"
    >
      <div :class="['LoginBrand-Inner', { active: isActive }]">
        <div class="Logo">
          <img :src="require(`@/assets/Images/Logos/${slug}.svg`)" :alt="`Logo-Brand-${slug}`" />
        </div>
        <div class="Brand">
          <div class="Brand-Name">{{ name }}</div>
          <div class="Brand-Url">{{ frontendHost }}</div>
        </div>
        <div :class="['Check', { 'Check--active': isActive }]"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import { BRANDS } from '@/constants/Brands';

export default {
  name: 'LoginBrands',
  data: () => ({
    activeBrand: '',
  }),
  computed: {
    ...mapState('Settings', ['brands']),
    filteredBrands() {
      return this.brands
        .filter(({ slug }) => {
          return !!BRANDS.find(({ brand }) => brand.toLowerCase() === slug);
        })
        .map((brand) => {
          return { ...brand, isActive: brand.slug === this.activeBrand };
        });
    },
  },
  mounted() {
    this.getBrands().then(() => {
      const { slug } = this.brands.find((v) => v);
      this.onSelectBrand(slug);
    });
  },
  methods: {
    ...mapActions('Settings', ['getBrands']),
    onSelectBrand(brand) {
      this.activeBrand = brand;
      this.$emit('select-brand', brand);
    },
  },
};
</script>
