<template>
  <div v-if="items.length" class="QuestionaryEmployeeList">
    <div v-if="!isSurname" class="Top">
      <div class="Left">{{ $t('admin.pages.journal.questionary.employees') }}:</div>
      <div class="Right">
        <span class="Button" @click="openSelectEmployeeModal">
          <BaseIcon name="plus" class="me-1" />
          {{ $t('base-actions.add') }}
        </span>
      </div>
    </div>
    <div class="Bottom">
      <div class="List">
        <ParticipantCard
          v-for="(item, index) in localList"
          :key="`ParticipantCard-${item.id}`"
          :item="item"
        >
          <template #actions>
            <span class="Button--reset Button" @click="deleteItem(index)">
              <BaseIcon height="16" width="16" name="x-close" />
            </span>
          </template>
        </ParticipantCard>
      </div>
    </div>
    <QuestionarySelectEmployee
      :id="nameModalOfSelect"
      :key="updateKey"
      :title="$t('modals.questionary.choose-employees')"
      :checked-options="checkedOptions"
      :disabled-options="disabledOptions"
      :items="localModalList"
      @save="saveSelectEmployeeModal"
      @cancel="closeSelectEmployeeModal"
    />
  </div>
</template>

<script>
import { ObjectFunctions } from '@/services/Helpers';
import BaseIcon from '@/components/Base/BaseIcon';
import ParticipantCard from '@/components/General/ParticipantCard';
import QuestionarySelectEmployee from '@/views/Estimation/Questionary/_components/QuestionaryForm/Modal/QuestionarySelectEmployee';

export default {
  name: 'QuestionaryEmployeeList',
  components: { BaseIcon, ParticipantCard, QuestionarySelectEmployee },
  props: {
    id: {
      type: String,
      required: true,
    },
    selectedOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
    type: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    excludeItems: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data: () => ({
    localList: [],
    localModalList: [],
    updateKey: 0,
    nameModalOfSelect: 'modal-choose-employees',
  }),
  computed: {
    isSurname() {
      return this.type === 'surname';
    },
    checkedOptions() {
      return this.localList.map(({ id }) => id) || [];
    },
    disabledOptions() {
      const ids = this.localList.map(({ id }) => id);
      return this.excludeItems.filter((id) => !ids.includes(id)) || [];
    },
  },
  watch: {
    items: {
      immediate: true,
      handler(values) {
        if (this.isSurname) {
          this.localList = ObjectFunctions.deepClone(values);
        } else if (this.selectedOptions) {
          const idsSelectedOptions = this.selectedOptions.map(({ id }) => id);
          this.localList = ObjectFunctions.deepClone(
            values.filter((item) => idsSelectedOptions.includes(item.id)),
          );
        } else {
          this.localList = [];
        }
        this.localModalList = ObjectFunctions.deepClone(values);
      },
    },
    localList: {
      immediate: true,
      handler(values) {
        if (values.length === 0) {
          return;
        }
        this.updateKey++;
        this.$emit('update-employees', values);
      },
    },
  },
  methods: {
    deleteItem(index) {
      this.localList.splice(index, 1);
    },
    openSelectEmployeeModal() {
      this.$bvModal.show(this.nameModalOfSelect);
    },
    closeSelectEmployeeModal() {
      this.$bvModal.hide(this.nameModalOfSelect);
      this.updateKey++;
    },
    saveSelectEmployeeModal(values) {
      this.$bvModal.hide(this.nameModalOfSelect);
      this.localList = values;
    },
  },
};
</script>
