<template>
  <div class="Navigation" :class="{ 'Navigation--Show ScrollBar--hide': isOpenMenu }">
    <div class="Navigation-Top">
      <div class="Navigation-Mobile-Menu">
        <div class="Navigation-Menu" @click="toggleMenu(false)">
          <BaseIcon name="x-close" />
        </div>
        <div class="Navigation-Logo">
          <AppLogo />
        </div>
      </div>
      <div v-if="brands.length > 0" class="Navigation-Brands">
        <BaseSelect
          v-model="selectedBrand"
          :items="brands"
          option-key="name"
          option-value="brand"
          hide-list-if-one-item
        >
          <template #selected="{ item }">
            <AppBrand :url="item.url" :name="item.name" :icon="item.icon" />
          </template>
          <template #item="{ item }">
            <AppBrand :url="item.url" :name="item.name" :icon="item.icon" />
          </template>
        </BaseSelect>
      </div>
      <AppNavigationLinks :items="navigationTop" @emit="callCallback" />
    </div>
    <div class="Navigation-Bottom">
      <AppNavigationLinks :items="navigationBottom" @emit="callCallback" />
      <div class="Navigation-MobileActions">
        <AppThemeSwitcher />
        <AppLangSwitcher />
      </div>
      <div class="Navigation-Legal">
        <router-link
          :to="{ name: 'legal' }"
          class="Navigation-Link"
          active-class="Navigation-Link--active"
          :exact="true"
          append
        >
          <span class="Navigation-Link-In">
            <span class="Name">
              <BaseIcon name="help-circle" />
              <span>{{ $t('terms') }}</span>
              <BaseIcon name="chevron-right" />
            </span>
          </span>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';

import BaseIcon from '@/components/Base/BaseIcon';
import BaseSelect from '@/components/Base/BaseSelect';

import AppLogo from '@/components/App/AppLogo';
import AppThemeSwitcher from '@/components/App/AppThemeSwitcher';
import AppLangSwitcher from '@/components/App/AppLangSwitcher';
import AppNavigationLinks from '@/components/App/AppNavigationLinks';

import { NAVIGATION_BOTTOM, NAVIGATION_TOP } from '@/constants/Navigation';
import { BRANDS } from '@/constants/Brands';

import AppBrand from '@/components/App/AppBrand';

export default {
  name: 'Navigation',
  components: {
    AppLogo,
    AppThemeSwitcher,
    AppLangSwitcher,
    AppNavigationLinks,
    BaseIcon,
    BaseSelect,
    AppBrand,
  },
  data: () => ({
    selectedBrand: '',
    navigationTop: NAVIGATION_TOP,
    navigationBottom: NAVIGATION_BOTTOM,
  }),
  computed: {
    ...mapState({
      isOpenMenu: (state) => state.Settings.toggleMenu,
      questionarySelectedState: (state) => state.Journal.questionarySelectedState,
    }),
    ...mapState('Settings', ['brand']),
    ...mapGetters('Admin', ['userBrands', 'activeUserBrand']),
    brands() {
      return BRANDS.filter(
        (brand) => this.userBrands.includes(brand.name) && this.activeUserBrand.name === brand.name,
      );
    },
  },
  watch: {
    selectedBrand(value) {
      if (value && value !== this.brand) {
        this.switchBrand({ name: value }).then(() => {
          this.setBrand(value);
          this.$changeBrand(value);
          window.location.reload();
        });
      }
    },
    activeUserBrand(activeBrand) {
      const { name } = activeBrand;
      if (name) {
        this.$changeBrand(name);
        this.setBrand(name);
        this.selectedBrand = name;
      }
    },
  },
  beforeDestroy() {
    this.toggleMenu(false);
  },
  methods: {
    ...mapActions({
      toggleMenu: 'Settings/toggleMenu',
      switchBrand: 'Admin/switchBrand',
    }),
    ...mapMutations({
      setBrand: 'Settings/setBrand',
      setSelectedQuestionaryState: 'Journal/setSelectedQuestionaryState',
    }),
    callCallback(callback = null) {
      if (this.isOpenMenu) this.toggleMenu(false);
      if (callback) this.$emit(callback);
      if (this.questionarySelectedState) this.setSelectedQuestionaryState();
    },
  },
};
</script>
